<template>
  <div class="download" :style="{'minHeight':cHeight}">
    <div class="banner">
      <div class="banner_in">
        <img src="../assets/img_agreement.png" alt="">
      </div>
    </div>
    <div class="main">
      <div class="main_in">
        <div>本协议是您与律兜客户端所有者无锡中铠信息咨询服务有限公司之间就律兜客户端服务等相关事宜所订立的协议，请您仔细阅读本注册协议，您点击“注册”后，本协议即对双方生效。</div>
        <div class="m60">第一条 服务条款的确认和接纳</div>
        <div class="m30">
          1、律兜客户端的各项电子服务的所有权和运作权归无锡中铠信息咨询服务有限公司所有。用户同意所有注册协议条款并完成注册程序，才能成为律兜客户端的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
        </div>
        <div class="m30">2、用户点击同意本协议的，即视为用户确认自己具有享受律兜客户端服务、下单购买等相应的权利能力和行为能力，能够独立承担法律责任。</div>
        <div>3、无锡中铠信息咨询服务有限公司保留在法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。</div>
        <div class="m60">第二条 律兜客户端服务</div>
        <div>
          无锡中铠信息咨询服务有限公司通过律兜客户端为用户与律师之间提供即时通讯、服务交互、费用结算等服务，以便律师可以在律兜平台上为用户提供文字咨询、电话咨询以及文书起草与修改等服务。用户在完全同意本协议及律兜客户端规定的情况下，方有权享受律兜客户端的相关服务。
        </div>
        <div class="m60">第三条 用户信息</div>
        <div class="m30">
          1、用户应自行诚信向律兜客户端提供注册资料，用户承诺其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且无锡中铠信息咨询服务有限公司保留终止用户使用律兜客户端各项服务的权利。
        </div>
        <div class="m30">
          2、用户在律兜客户端进行注册购买活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，无锡中铠信息咨询服务有限公司将予以严格保密，除非得到用户的授权或法律另有规定，本站不会向外界披露用户隐私信息。
        </div>
        <div class="m30">
          3、用户注册成功后，将产生用户名和密码等账户信息，您可以根据律兜客户端规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知律兜客户端。</div>
        <div>4、用户同意，无锡中铠信息咨询服务有限公司拥有通过邮件、短信电话等形式，向在律兜客户端注册、购买的用户发送订单信息、促销活动等告知信息的权利。</div>
        <div class="m60">第四条 产品信息</div>
        <div>
          律兜客户端上的律师服务价格、数量、能否提供等信息随时都有可能发生变动，律兜客户端不作特别通知。律兜客户端显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；无锡中铠信息咨询服务有限公司欢迎纠错，并会视情况给予纠错者一定的奖励。为表述便利，产品和服务简称为“产品”或“服务”。
        </div>
        <div class="m60">第五条 订单</div>
        <div>在您下订单时，请您仔细确认所购律师服务的名称、价格、数量、说明等信息。联系人与用户本人不一致的，联系人的行为和意思表示视为用户的行为和意思表示，用户应对联系人的行为及意思表示的法律后果承担连带责任。</div>
        <div class="m60">第六条 所有权及知识产权条款</div>
        <div class="m30">
          1、用户一旦接受本协议，即表明该用户主动将其在任何时间段在律兜客户端发表的任何形式的信息内容（包括但不限于客户评价、客户咨询等信息内容）的财产性权利等任何可转让的权利，全部独家且不可撤销地转让给无锡中铠信息咨询服务有限公司所有，用户同意无锡中铠信息咨询服务有限公司有权就任何主体侵权而单独提起诉讼。
        </div>
        <div class="m30">2、用户同意并已充分了解本协议的条款，承诺不将已发表于律兜客户端的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。</div>
        <div>
          3、除法律另有强制性规定外，未经无锡中铠信息咨询服务有限公司明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用律兜客户端的信息内容，否则，无锡中铠信息咨询服务有限公司有权追究其法律责任。
        </div>
        <div class="m60">第七条 责任限制</div>
        <div class="m30">
          1、除非另有明确的书面说明，律兜客户端及其所包含的或以其它方式通过律兜客户端提供给您的全部信息、内容、材料、产品和服务，均是在“按现状”和“按现有”的基础上提供的。除非另有明确的书面说明，无锡中铠信息咨询服务有限公司不对本客户端的运营及其包含在律兜客户端上的信息、内容、材料、产品或服务作任何形式的、明示或默示的声明或担保。
        </div>
        <div class="m30">
          2、无锡中铠信息咨询服务有限公司作为信息技术服务平台，致力于为广大用户获得律师正确、完整的法律解答等服务，但不对律师的解答的正确性和完整性以及律师所提供的所有服务承担任何责任，且不对因解答的不正确或遗漏导致的任何损失或损害承担责任，律师提供的所有服务所产生的责任根据《中华人民共和国律师法》规定由提供服务的律师及所在律所承担责任。
        </div>
        <div class="m30">3、无锡中铠信息咨询服务有限公司所提供的任何法律信息，仅供参考，如解答与实际判决、裁定发生偏差，无锡中铠信息咨询服务有限公司概不负责，亦不负任何法律责任。</div>
        <div class="m30">4、是否采用无锡中铠信息咨询服务有限公司的合作律师意见为提问者个人行为，无锡中铠信息咨询服务有限公司不承担任何可能产生的责任。</div>
        <div>5、无锡中铠信息咨询服务有限公司保留对本声明作出不定时修改的权利。</div>
        <div class="m60">第八条 服务内容</div>
        <div class="m30">
          1、本条款所称“咨询服务”特指用户通过律兜客户端提交的文字咨询、电话咨询以及文书的起草与修改等服务，咨询服务的交互主体为律兜客户端的用户和无锡中铠信息咨询服务有限公司合作律师及其所在律所，无锡中铠信息咨询服务有限公司作为信息技术服务平台仅提供信息技术服务，不参与且不提供任何法律服务。
        </div>
        <div class="m30">2、如服务在进行过程中由于律兜平台性能不稳定等系统原因导致服务不能完成的，将由律兜APP客服为您安排重新对接合作律师。</div>
        <div class="m30">3、用户必须在注册及申请收费服务时，详细阅读律兜客户端使用说明信息，并严格按要求操作。在个人信息部分必须提供真实的用户信息。</div>
        <div class="m30">
          4、一旦发现用户提供的个人信息中有虚假，无锡中铠信息咨询服务有限公司有权立即终止向用户提供的所有服务，并冻结用户的帐户，有权要求用户赔偿因提供虚假信息给律师及无锡中铠信息咨询服务有限公司造成的损失。</div>
        <div class="m30">5、咨询均只限于根据用户的主观描述，律师尽可能利用自身法律知识及实践经验给予一定的解惑及如何处理的建议，不保证建议一定能获得好的效果。不对结果是否符合用户预期做保证。</div>
        <div class="m30">6、咨询中律师所提供内容均是个人建议，擅自将律师建议做为处置方法的，后果自负，与律师以及无锡中铠信息咨询服务有限公司无关。</div>
        <div class="m30">7、用户在咨询过程中要语言文明，尊重律师，平等交流。如有对律师恶意中伤，语言不文明，律师有权立即中断服务，用户无权要求退款。</div>
        <div class="m30">8、电话咨询过程中遇到律师有紧急事情处理时，要给予理解，暂停咨询，律师应当安排重新咨询的具体时间。</div>
        <div class="m30">
          9、用户在平台上获取律师服务所支付的律师服务费用由平台代收后支付给律师及其所在律所，用户需要开具发票的，可向律兜平台提交申请，由提供服务的合作律师所在律所向用户开具服务类发票。用户在平台上其他购买信息技术服务的费用由平台收取，用户需要开具发票的，可向律兜平台提交申请，由无锡中铠信息咨询服务有限公司向用户开具服务类发票。
        </div>
        <div>
          10、鉴于为用户提供法律服务的为律兜合作律师，用户咨询过程中产生的责任由律兜合作律师及其所在律所进行承担，律兜平台仅仅作为信息提供者不承担任何责任。实际责任发生后，用户因追究咨询律兜合作律师及其所在律所责任的，律兜平台应当积极配合用户，包括向用户提供律兜合作律师的注册信息。
        </div>
        <div class="m60">第九条 无锡中铠信息咨询服务有限公司与用户双方的权利及义务</div>
        <div class="m30">1、无锡中铠信息咨询服务有限公司有义务在现有技术上维护平台服务的正常进行，并努力提升技术及改进技术，使律兜APP服务更好进行。</div>
        <div class="m30">2、对于用户在律兜客户端预定服务中的不当行为或其它任何合作律师认为应当终止服务的情况，无锡中铠信息咨询服务有限公司有权随时作出删除相关信息、终止服务提供等处理，而无须征得用户的同意。
        </div>
        <div class="m30">
          3、咨询双方因服务引起的纠纷，请无锡中铠信息咨询服务有限公司给予调解的，无锡中铠信息咨询服务有限公司将有权了解相关信息，并将双方提供的信息与对方沟通。因在律兜APP上发生服务纠纷，引起诉讼的，用户通过司法部门或行政部门依照法定程序要求无锡中铠信息咨询服务有限公司提供相关数据，无锡中铠信息咨询服务有限公司应积极配合并提供有关资料。
        </div>
        <div class="m30">4、无锡中铠信息咨询服务有限公司有权对用户的注册数据及电话咨询的行为进行查阅，发现注册数据或咨询行为中存在任何问题或怀疑，均有权向用户发出询问及要求改正的通知或者直接作出删除等处理。
        </div>
        <div class="m30">5、当用户顺利使用完付费咨询服务后不得因为咨询服务中的内容不满意要求退款。</div>
        <div class="m30">6、用户对咨询内容不满意，可以向客服提出投诉，无锡中铠信息咨询服务有限公司有义务依据情况协调沟通，维护合作律师和用户关系和谐。</div>
        <div>7、系统因下列状况无法正常运作，使用户无法使用电话咨询服务时，无锡中铠信息咨询服务有限公司不承担损害赔偿责任，该状况包括但不限于：</div>
        <div>（1）系统停机维护期间；</div>
        <div>（2）电信设备出现故障不能进行数据传输的；</div>
        <div>（3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因 素，造成系统障碍不能执行业务的；</div>
        <div class="m30">（4）由于黑客攻击、电信部门技术调整或故障、银行方面的问题等原因而造成的服务 中断或者延迟。</div>
        <div>
          8、用户如需通过律兜客户端委托无锡中铠信息咨询服务有限公司帮其对接律师提供委托代理服务，用户应当将最终确定的律师费先行支付给无锡中铠信息咨询服务有限公司，由无锡中铠信息咨询服务有限公司协助用户与律师及其所在律所完成《中华人民共和国律师法》所要求的委托代理手续，再按照用户与律所签订的委托代理协议进行费用支付与服务提供。
        </div>
        <div class="m60">第十条 协议更新及用户关注义务</div>
        <div>
          根据国家法律法规变化及网络运营需要，无锡中铠信息咨询服务有限公司有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在律兜客户端上即生效，并代替原来的协议。用户可随时登陆查阅最新协议；用户有义务不时关注并阅读最新版的协议及客户端公告。如用户不同意更新后的协议，可以且应立即停止接受律兜客户端依据本协议提供的服务；如用户继续使用律兜客户端提供的服务，即视为同意更新后的协议。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
        </div>
        <div class="m60">第十一条 法律管辖和适用</div>
        <div>
          本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国法律。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，由无锡中铠信息咨询服务有限公司所在地的人民法院管辖。
        </div>
        <div class="m60">第十二条 其他</div>
        <div>1、无锡中铠信息咨询服务有限公司尊重用户和消费者的合法权利，本协议及本客户端上发布的各
          类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本客户端欢迎用户和社会各界提出意见和建议，无锡中铠信息咨询服务有限公司将虚心接受并适时修改本协议及本客户端的各类规则。</div>
        <div>2、您点击本协议上方的“同意以下协议，提交”按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'download',
    data() {
      return {
        cHeight: null
      }
    },
    mounted() {
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) + 'px'

    },
    methods: {

    },
    components: {}
  }

</script>

<style scoped lang="scss">
  .download {
    background: #F5F5F8;
    box-sizing: border-box;
  }

  .banner {
    img {
      width: 100%;

    }
  }

  .main {
    .main_in {
      margin: 30px 30px 0;
      font-size: 26px;
      color: #333;

      .m60 {
        margin: 40px 0;
      }

      .m30 {
        margin-bottom: 30px;
      }
    }

  }

</style>
<style lang="scss">


</style>
